<template>
  <div class="Client font">
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>

      <div class="content-wrapper container-xxl p-0 font" v-if="permissionCheck.viewAny == false">
        <div class="p-2">
          <img style="width: 100%" src="/assets/image/accessDenide.png" alt="" />
        </div>
      </div>
      <div class="content-wrapper container-xxl p-0 font" v-else>
        <banner title="Upgrades" :breadcrumb="[
          {
            label: 'Dashboard',
          },
          { label: 'upgrades' },
        ]">
        </banner>
        <div v-if="loading == true">
          <Spinner />
        </div>

        <div class="row font" style="margin-top: -45px">
          <div class="col-xl-12 col-md-12 col-sm-12 col-12">
            <div class="d-xl-none d-md-none d-block pt-1"></div>
            <div class="card custom-card" >
              <div class="card-body">
                <div class="row">
                  <div class="col text font">

                    <h4><b style="color: #00364f; font-weight: bolder">Upgrads</b></h4>

                  </div>
                  <div class="col text-end ">
                    <button type="button" data-bs-toggle="modal" data-bs-target="#new-task-modal" title="Filter"
                      class="btn text-white btn-sm" style="background-color: #00364f"> <strong> <font-awesome-icon
                          icon="filter" /></strong></button>

                  </div>
                  <div class="col-xl-1 col-md-1 d-md-block d-xl-block d-none cus-p-1" style="margin-top:-5px">

                    <select style="height: 30px; padding: 5px 5px 5px 5px" class="form-select" @change="changePageNo"
                      aria-label="Default select example">

                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                </div>



                <div class="row">
                  <div class="d-md-none d-xl-none d-block col-3 text font">

                    <select style="height: 30px; padding: 5px 5px 5px 5px" class="form-select" @change="changePageNo"
                      aria-label="Default select example">
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>

                    </select>
                  </div>

                </div>
                <!-- start list area  -->
                <div class="row" style="margin-top:5px">
                 
                  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="container-fluid table-scroll" style="margin-left: -5px">
                      <table class="table table-hover table-sm text font">
                        <tr class="text tr-head table-wrapper-scroll-y rounded-circle">
                          <th class="text-truncate">Date</th>
                          <th class="text-truncate">Project ID</th>
                          <th class="text-truncate">Professional</th>
                          <th class="text-truncate">Title</th>
                          <th class="text-truncate">Amount</th>
                          <th class="text-truncate">Note</th>
                          <th class="text-truncate">Remark</th>

                          <th class="text-truncate">Status</th>
                          <th class="text-truncate" v-if="permissionCheck.kycApprove != false">
                            Action
                          </th>
                        </tr>
                        <tbody class="text-truncate">
                          <tr v-for="(project, index) in clientServices" :key="index">
                            <td>
                              <span v-if="project">
                                {{ dateTime(project.date) }}
                              </span>
                            </td>

                            <td>
                              <router-link :to="`/staff/project-detailes/${project.client_service.id}`">
                                <strong v-if="project.client_service" style="color: #f21000;">
                                  {{ project.client_service.clientServiceNo }}</strong>
                              </router-link>

                            </td>
                            <td class="text-truncate">
                              <span v-if="project.professional">
                                <div>
                                  <strong>
                                    {{ project.professional.name }}
                                  </strong>
                                </div>
                                {{ project.professional.code }}
                              </span>
                            </td>
                            <td class="text-truncate">{{ project.title }}</td>
                            <td class="text-truncate">{{ project.amt }}</td>
                            <td>{{ project.note }}</td>
                            <td>{{ project.remark }}</td>
                            <td class="text-truncate">
                              <span v-if="project.status == 'approved'" style="
                                    width: 80px;
                                    background-color: #430194;
                                    color: white;
                                    box-shadow: 0px 0px 5px 0px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding: 3px 10px 3px 10px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  ">
                                {{ project.status }}
                              </span>

                              <span v-if="project.status == 'paid'" style="
                                    width: 80px;
                                    background-color: green;
                                    color: white;
                                    box-shadow: 0px 0px 5px 0px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding: 3px 10px 3px 10px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  ">
                                {{ project.status }}
                              </span>

                              <span v-if="project.status == 'cancel'" style="
                                    width: 80px;
                                    background-color: #f21000;
                                    color: white;
                                    box-shadow: 0px 0px 5px 0px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding: 3px 10px 3px 10px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  ">
                                {{ project.status }}
                              </span>

                              <span v-if="project.status == 'pending'" style="
                                    width: 80px;
                                    background-color: orange;
                                    color: white;
                                    box-shadow: 0px 0px 5px 0px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding: 3px 10px 3px 10px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  ">
                                {{ project.status }}
                              </span>
                              <span v-if="project.status == 'reject'" style="
                                    width: 80px;
                                    background-color: black;
                                    color: white;
                                    box-shadow: 0px 0px 5px 0px gray;
                                    border-radius: 50px;
                                    font-size: 10px;
                                    padding: 3px 10px 3px 10px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                  ">
                                {{ project.status }}
                              </span>
                            </td>
                            <!-- <td class="text-truncate">
                              <p
                                v-if="project.status == 'pending'"
                                class="btn btn-sm"
                                style="
                                  width: 80px;
                                  background-color: gray;
                                  color: white;
                                  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding: 3px 3px 3px 3px;
                                "
                              >
                                <b>{{ project.status }}</b>
                              </p>
                              <p
                                v-if="project.status == 'approved'"
                                class="btn btn-sm"
                                style="
                                  width: 80px;
                                  background-color: #facd02;
                                  color: white;
                                  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding: 3px 3px 3px 3px;
                                "
                              >
                                <b>{{ project.status }}</b>
                              </p>
                              <p
                                v-if="project.status == 'paid'"
                                class="btn btn-sm"
                                style="
                                  width: 80px;
                                  background-color: #3ab04b;
                                  color: white;
                                  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding: 3px 3px 3px 3px;
                                "
                              >
                                <b>{{ project.status }}</b>
                              </p>
                              <p
                                v-if="project.status == 'processed'"
                                class="btn btn-sm"
                                style="
                                  width: 80px;
                                  background-color: #ffaa00;
                                  color: white;
                                  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding: 3px 3px 3px 3px;
                                "
                              >
                                <b>{{ project.status }}</b>
                              </p>

                              <p
                                v-if="project.status == 'hold'"
                                class="btn btn-sm"
                                style="
                                  width: 80px;
                                  background-color: #8f8d8e;
                                  color: white;
                                  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding: 3px 3px 3px 3px;
                                "
                              >
                                <b>{{ project.status }}</b>
                              </p>

                              <p
                                v-if="project.status == 'rejected'"
                                class="btn btn-sm"
                                style="
                                  width: 80px;
                                  background-color: black;
                                  color: white;
                                  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding: 3px 3px 3px 3px;
                                "
                              >
                                <b>{{ project.status }}</b>
                              </p>

                              <p
                                v-if="project.status == 'cancel'"
                                class="btn btn-sm"
                                style="
                                  width: 80px;
                                  background-color: #f50303;
                                  color: white;
                                  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding: 3px 3px 3px 3px;
                                "
                              >
                                <b>{{ project.status }}</b>
                              </p>
                              <p
                                v-if="project.status == 'upgrade'"
                                class="btn btn-sm"
                                style="
                                  width: 80px;
                                  background-color: #ee0def;
                                  color: white;
                                  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                                  border-radius: 50px;
                                  font-size: 10px;
                                  padding: 3px 3px 3px 3px;
                                "
                              >
                                <b>{{ project.status }}</b>
                              </p>
                            </td> -->
                            <td class="text-truncate text-end" v-if="permissionCheck.kycApprove != false">
                              <!-- <button type="button" data-bs-toggle="modal" data-bs-target="#addon"
                                  class="btn btns btn-sm btn-success" @click="editModule(mod)"
                                  style="padding:5px 4.5px 5px 4.5px;">
                                  <font-awesome-icon icon="edit" />
                                </button> -->
                              <div class="btn-group btn-group-sm" role="group">
                                <!-- <router-link
                                  :to="`/staff/project-detailes/${project.id}`"
                                  class="btn btns btn-sm text-white"
                                  style="
                                    padding: 5px 4.5px 5px 4.5px;
                                    background-color: #00364f;
                                  "
                                >
                                  <font-awesome-icon icon="eye" />
                                </router-link> -->
                                <!-- <router-link
                                  to="#"
                                  class="btn btns btn-sm text-white"
                                  style="
                                    padding: 5px 4.5px 5px 4.5px;
                                    background-color: green;
                                  "
                                >
                                  <font-awesome-icon icon="edit" />
                                </router-link> -->
                                <router-link to="#" v-if="project.status == 'pending'"
                                  @click="valueAssignUpgrade(project)" data-bs-toggle="modal"
                                  data-bs-target="#approvedModel" class="btn btns btn-sm text-white" style="
                                      padding: 5px 4.5px 5px 4.5px;
                                      background-color: green;
                                    ">
                                  <i class="fa fa-check-square-o" aria-hidden="true"></i>
                                </router-link>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- end list area -->
              </div>
              <div class="card-footer">
                <div class="row">

                  <div class="col-md-4 col-12 text-md-start text-center cus-p-1">

                    <p class="mb-0">
                      Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.total }} entries
                    </p>
                  </div>
                  <div class="col-md-8 col-12 text-md-end text-center cus-p-1">
                    <ul class="pagination  justify-content-center">
                      <li class="page-item" @click="loadClients(null, 'first')">
                        <a class="page-link" href="#" aria-label="Previous">
                          <span aria-hidden="true">&laquo;</span>
                        </a>
                      </li>
                      <li class="page-item" @click="loadClients(null, 'prev')">
                        <a class="page-link" href="#">Previous</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page > 3"
                        @click="loadClients(pagination.current_page - 3)">
                        <a class="page-link" href="#">{{
                          pagination.current_page - 3
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page > 2"
                        @click="loadClients(pagination.current_page - 2)">
                        <a class="page-link" href="#">{{
                          pagination.current_page - 2
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page > 1"
                        @click="loadClients(pagination.current_page - 1)">
                        <a class="page-link" href="#">{{
                          pagination.current_page - 1
                        }}</a>
                      </li>
                      <li class="active page-item" @click="loadClients(pagination.current_page)">
                        <a class="page-link" href="#">{{ pagination.current_page }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 4"
                        @click="loadClients(pagination.current_page + 1)">
                        <a class="page-link" href="#">{{
                          pagination.current_page + 1
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 5"
                        @click="loadClients(pagination.current_page + 2)">
                        <a class="page-link" href="#">{{
                          pagination.current_page + 2
                        }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 6"
                        @click="loadClients(pagination.current_page + 3)">
                        <a class="page-link" href="#">{{
                          pagination.current_page + 3
                        }}</a>
                      </li>
                      <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="loadClients( pagination.current_page + 4 )"><a href="#">{{pagination.current_page + 4}}</a></li> -->
                      <li class="page-item">
                        <a class="page-link" href="#">...</a>
                      </li>

                      <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="loadClients( pagination.last_page - 4 )"><a href="#">{{pagination.last_page - 4}}</a></li> -->
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 3"
                        @click="loadClients(pagination.last_page - 3)">
                        <a class="page-link" href="#">{{ pagination.last_page - 3 }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 2"
                        @click="loadClients(pagination.last_page - 2)">
                        <a class="page-link" href="#">{{ pagination.last_page - 2 }}</a>
                      </li>
                      <li class="page-item" v-if="pagination.current_page < pagination.last_page - 1"
                        @click="loadClients(pagination.last_page - 1)">
                        <a class="page-link" href="#">{{ pagination.last_page - 1 }}</a>
                      </li>

                      <li class="page-item" @click="loadClients(null, 'next')">
                        <a class="page-link" href="#">Next</a>
                      </li>
                      <li class="page-item" @click="loadClients(null, 'last')">
                        <a class="page-link" href="#">&raquo;</a>
                      </li>
                    </ul>
                  </div>

                  <!-- <div class="col-md-1 col-4 text-end">
      <select
                      style="height: 30px; padding: 5px 5px 5px 5px"
                      class="form-select"
                      @change="changePageNo"
                      aria-label="Default select example"
                    >
         
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
    </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>

  <!-- Right Sidebar starts -->
  <div class="modal modal-slide-in sidebar-todo-modal fade" id="new-task-modal">
    <div class="modal-dialog sidebar-sm">
      <div class="modal-content p-0">
        <form id="form-modal-todo" class="todo-modal needs-validation" novalidate onsubmit="return false">
          <div class="modal-header align-items-center mb-1" style="background-color: #00364f">
            <h5 class="modal-title text-white"><strong>Project Filter</strong></h5>
            <div class="todo-item-action d-flex align-items-center justify-content-between ms-auto text-white">
              <span class="todo-item-favorite cursor-pointer me-75 text-white"><i data-feather="star"
                  class="font-medium-2"></i></span>
              <i data-feather="x" class="cursor-pointer" data-bs-dismiss="modal" stroke-width="3">X</i>
            </div>
          </div>
          <div class="modal-body flex-grow-1 pb-sm-0 pb-3">



            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <div class="form-check">
                  <input @click="valueNullFilter()" v-model="filterType" value="title" class="form-check-input"
                    type="radio" name="flexRadioDefault" id="title">
                  <label class="form-check-label" for="title">
                    Title
                  </label>
                </div>
              </li>


              <li class="list-group-item">
                <div class="form-check">
                  <input @click="valueNullFilter()" v-model="filterType" value="date" class="form-check-input"
                    type="radio" name="flexRadioDefault" id="date">
                  <label class="form-check-label" for="date">
                    Date
                  </label>
                </div>
              </li>

              <li class="list-group-item">
                <div class="form-check">
                  <input @click="valueNullFilter()" v-model="filterType" value="client_service_id"
                    class="form-check-input" type="radio" name="flexRadioDefault" id="client_service_id">
                  <label class="form-check-label" for="client_service_id">
                    Project ID
                  </label>
                </div>
              </li>
              <li class="list-group-item">
                <div class="form-check">
                  <input @click="valueNullFilter()" v-model="filterType" value="all" class="form-check-input" type="radio"
                    name="flexRadioDefault" id="all">
                  <label class="form-check-label" for="all">
                    All
                  </label>
                </div>
              </li>
            </ul>


            <div class="pt-1 pb-1">
              <!-- <label for="exampleFormControlInput1" class="form-label">Email address</label> -->
              <input type="date" v-if="filterType == 'date' || filterType == 'dueDate' || filterType == 'completionDate'"
                class="form-control datepicker" v-model="filterValue" id="exampleFormControlInput1"
                placeholder="Enter Value">
              <input type="text" v-if="filterType == 'title'" class="form-control" v-model="filterValue"
                id="exampleFormControlInput1" placeholder="Enter Value">
              <input type="text" v-if="filterType == 'client_service_id'" @change="findRecordeByProjectId"
                class="form-control" v-model="filterValue" id="exampleFormControlInput1" placeholder="Enter Value">








            </div>

            <button v-if="filterType != 'client_service_id'" @click="loadClients()" type="button"
              class="btn btn-success btn-sm">Success</button>




          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- Right Sidebar ends -->

  <!-- Modal -->
  <div class="modal fade" id="professional" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text" id="exampleModalLabel"><b>Assign Projects</b></h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="assignToProfessional">
            <div class="row font">
              <div class="col-xl-6 col-lg-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <div class="container-fluid table-scroll border font" style="border-radius: 8px">
                  <nav>
                    <div class="text-center" style="
                          background-color: #00364f;
                          color: white;
                          padding: 5px 5px 5px 5px;
                        ">
                      <strong>Internal Professional</strong>
                    </div>
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item" v-for="(IntProfessional, index) in Internalprofessionals" :key="index">
                        <div class="row font" data-bs-toggle="collapse"
                          :href="`#Internalprofessionals${IntProfessional.id}`">
                          <div class="col-xl-2 col-lg-2 col-md-2 col-2">
                            <img style="width: 40px; height: 40px" :src="imageFind(IntProfessional)" alt="" />
                          </div>
                          <div class="col-xl-9 col-lg-9 col-md-9 col-9" style="line-height: 1; color: black">
                            <strong>{{ IntProfessional.orgnization }}</strong><br />
                            <small style="font-size: 0.8vw">{{
                              IntProfessional.specialization
                            }}</small><br />
                            <strong style="font-size: 0.8vw">Pending: 5, Completed: 10</strong>
                          </div>
                          <div class="col-xl-1 col-lg-1 col-md-1 col-1 text-end">
                            <!-- :value="checkedOrNot(IntProfessional.id)" -->
                            <input @click="valueAssign($event, IntProfessional.id, 'internal')"
                              :value="IntProfessional.id" class="form-checkbox" type="checkbox"
                              style="margin-right: -20px" v-model="proServices" />
                          </div>
                        </div>
                        <div class="row font pt-1">
                          <div class="col">
                            <div class="collapse" :id="`Internalprofessionals${IntProfessional.id}`">
                              <h4 class="font">
                                <strong style="color: black">TASKS</strong>
                              </h4>
                              <div v-for="(task, index) in tasks" :key="index">
                                <div class="col-12" v-if="task.step">
                                  <input @click="
                                    valueAssignInSteps($event, task, IntProfessional.id)
                                  " style="color: red" type="checkbox" v-model="proSteps" :value="task.step.id" />
                                  <label style="margin-left: 5px">
                                    {{ task.step.title }}</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-12" style="padding: 5px 5px 5px 5px">
                <div class="container-fluid table-scroll border font" style="border-radius: 8px">
                  <nav>
                    <div class="text-center" style="
                          background-color: #00364f;
                          color: white;
                          padding: 5px 5px 5px 5px;
                        ">
                      <strong>External Professional</strong>
                    </div>
                    <ul class="list-group list-group-flush">
                      <li class="list-group-item" v-for="(extProfessional, index) in Externalprofessionals" :key="index">
                        <div class="row font" data-bs-toggle="collapse"
                          :href="`#Internalprofessionals${extProfessional.id}`">
                          <div class="col-xl-2 col-lg-2 col-md-2 col-2">
                            <img style="width: 40px; height: 40px" :src="imageFind(extProfessional)" alt="" />
                          </div>
                          <div class="col-xl-9 col-lg-9 col-md-9 col-9" style="line-height: 1; color: black">
                            <strong>{{ extProfessional.orgnization }}</strong><br />
                            <small style="font-size: 0.8vw">{{
                              extProfessional.specialization
                            }}</small><br />
                            <strong style="font-size: 0.8vw">Pending: 5, Completed: 10</strong>
                          </div>
                          <div class="col-xl-1 col-lg-1 col-md-1 col-1 text-end">
                            <input :value="extProfessional.id" v-model="proServices"
                              @click="valueAssign($event, extProfessional.id, 'external')" class="form-checkbox"
                              type="checkbox" style="margin-right: -20px" />
                          </div>
                        </div>
                        <div class="row font pt-1">
                          <div class="col">
                            <div class="collapse" :id="`Internalprofessionals${extProfessional.id}`">
                              <h4 class="font">
                                <strong style="color: black">TASKS</strong>
                              </h4>
                              <div v-for="(task, index) in tasks" :key="index">
                                <div class="col-12" v-if="task.step">
                                  <input @click="
                                    valueAssignInSteps($event, task, extProfessional.id)
                                  " v-model="proSteps" :value="task.step.id" style="color: red" type="checkbox" />
                                  <label style="margin-left: 5px">
                                    {{ task.step.title }}</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>

            <div class="modal-footer">
              <button type="button" class="btn btn-sm text-white" style="background-color: #f21300"
                data-bs-dismiss="modal">
                Close
              </button>
              <button type="submit" class="btn btn-sm text-white" style="background-color: #00364f">
                <b>Confirm </b>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div class="modal fade" id="projectcompleatedModel" tabindex="-1" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            <strong style="color: #00364f">Change Status</strong>
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="stsChangeStafSave">
            <div class="form-check">
              <input v-model="stsFromChange.status" class="form-check-input" type="radio" name="flexRadioDefault"
                id="flexRadioDefault1" value="rejected" />
              <label class="form-check-label" for="flexRadioDefault1">
                <strong>Reject</strong>
              </label>
            </div>
            <br />
            <div class="form-check">
              <input v-model="stsFromChange.status" value="completed" class="form-check-input" type="radio"
                name="flexRadioDefault" id="flexRadioDefault2" checked />
              <label class="form-check-label" for="flexRadioDefault2">
                <strong>Complete</strong>
              </label>
            </div>

            <div v-if="stsFromChange.status == 'rejected'">
              <br />
              <textarea placeholder="Remark" v-model="stsFromChange.remark" name="" id="" class="form-control"
                rows="3"></textarea>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-sm text-white" style="background-color: #f21000"
                data-bs-dismiss="modal">
                Close
              </button>
              <button type="submit" class="btn btn-sm text-white" style="background-color: #00364f">
                <strong> Confirm</strong>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div class="modal fade" id="approvedModel" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog model-sm">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Approved</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="approvedStatus()">
            <div class="form-group p-1">
              <label for="status">Status</label>
              <select style="padding: 5px 5px 5px 5px;" v-model="approvedFrom.status" class="form-select"
                aria-label="Default select example">
                <option selected disabled>Select...</option>
                <option value="approved">Approve</option>
                <option value="cancel">Cancel</option>
              </select>
            </div>
            <div class="form-group p-1">
              <label for="status">Remark</label>
              <textarea class="form-control" rows="3" v-model="approvedFrom.remark"></textarea>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-sm text-white" data-bs-dismiss="modal"
                style="background-color: #f21000">
                Close
              </button>
              <button type="submit" class="btn btn-sm text-white" style="background-color: #00364f">
                Confirm
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

//
<script>
import Permissions from "../../../mixins/permission";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import moment from "moment";
import Banner from "../../../components/staff/comman/Banner.vue";
import Spinner from "../../../components/staff/comman/Spinner.vue";
// import Form from "vform";
import jQuery from "jquery";
let $ = jQuery;
// var _ = require("lodash");
export default {
  name: "UpgradesStaff",
  mixins: [Permissions],
  components: {
    Banner,
    Spinner,
  },
  data() {
    return {
      filterValue: '',
      filterType: '',
      pagination: {
        current_page: 0,
        from: 0,
        to: 0,
        total: 0,
        last_page: 0,
        per_page: 0,
        first_page_url: null,
        last_page_url: null,
        next_page_url: null,
        prev_page_url: null,
        path: null,
      },
      per_page: 10,

      approvedFrom: {
        status: "",
        remark: "",
      },
      pre: true,
      proServices: [],
      proSteps: [],
      form: {
        client_service_id: null,
        service_id: null,
        professionals: [],
      },
      stsFromChange: {
        client_service_id: null,
        service_id: null,
        status: "",
        remark: "",
      },
      selectedpro: [],
      loading: false,
      Externalprofessionals: [],
      Internalprofessionals: [],
      clientServices: [],
      statusQueryArray: [],
      tasks: [],
      steps: [],
      assign_form: {
        client_service_id: null,
        multipleAsignIdProfessional: [],
        multipleAsignIdStaff: [],
      },
      client_service_id: null,
      upgrade_id: null,
      // permissions

      permissionCheck: {
        view: "",
        viewAny: true,
        kycView: "",
        kycCreate: "",
        kycUpdate: "",
        kycApprove: "",
      },
    };
  },
  methods: {
    permissionsCheker() {
      this.permissionCheck.view = this.loadPermissions("clientservice-upgrade-view");
      // this.permissionCheck.viewAny = this.loadPermissions(
      //   "clientservice-upgrade-view-any"
      // );

      this.permissionCheck.kycApprove = this.loadPermissions(
        "clientservice-upgrade-approve"
      );
    },
    changePageNo(e) {
      this.per_page = e.target.value;
      this.loadClients();
    },
    valueAssignUpgrade(record) {
      this.approvedFrom = {}
      this.approvedFrom.errors = {}
      this.upgrade_id = record.id;
      
    },
    approvedStatus() {
      var alertMsg =  ""
      if(this.approvedFrom.status == 'approved'){
        alertMsg = "Approved successfully."
      }else{
        alertMsg = "Cancelled successfully."
      }
      this.loading = true;
      this.$axios
        .post(
          `staff/clientserviceupgrade/approve/${this.upgrade_id}`,
          this.approvedFrom,
          {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          }
        )
        .then((res) => {
          res.data;

          toast.success(alertMsg, {
            autoClose: 1000,
          });
          $("#approvedModel").modal("hide");
          this.approvedFrom = {}
          this.loadClients();
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          // this.form.errors = error.response.data;
          this.$swal.fire({
            confirmButtonColor: "#00364f",
            icon: "error",
            text: error.response.data.error,
          });
        });
    },
    dateTime(value) {
      return moment(value).format("DD-MM-YYYY");
    },
    imageFind(professional) {
      var data;
      if (professional.logo != null) {
        data =
          this.$store.state.imgUrl +
          "/professional/" +
          professional.id +
          "/thumbs/" +
          professional.logo;
        return data;
      } else {
        data = this.$store.state.placeholderImg;
        return data;
      }
    },
    pushStatusvalueInArray(event, value) {
      if (event.target.checked == true) {
        this.statusQueryArray.push(value);
        this.loadClients();
      } else if (event.target.checked == false) {
        this.statusQueryArray.splice(this.statusQueryArray.indexOf(value), 1);
        this.loadClients();
      }
    },
    valueAssignInSteps(event, task, professional_id) {
      var i;
      if (event.target.checked == true) {
        for (i = 0; i < this.form.professionals.length; i++) {
          if (this.form.professionals) {
            if (professional_id === this.form.professionals[i].professional_id) {
              this.form.professionals[i].steps.push(task.step_id);
            }
          }
        }
      } else if (event.target.checked == false) {
        for (i = 0; i < this.form.professionals.length; i++) {
          if (this.form.professionals) {
            if (professional_id === this.form.professionals[i].professional_id) {
              this.form.professionals[i].steps.splice(
                this.form.professionals[i].steps.indexOf(task.step_id),
                1
              );
            }
          }
        }
      }
    },
    valueNullFilter() {
      this.filterValue = "";
    },
    loadClients(page = null, pg = null) {
      console.log(page);
      console.log(pg);
      this.loading = true;
      var pageUrl = "";
      if (this.pagination.last_page_url && pg == "last") {
        pageUrl = pageUrl + this.pagination.last_page_url;
      } else if (this.pagination.first_page_url && pg == "first") {
        pageUrl = pageUrl + this.pagination.first_page_url;
      } else if (this.pagination.prev_page_url && pg == "prev") {
        pageUrl = pageUrl + this.pagination.prev_page_url;
      } else if (this.pagination.next_page_url && pg == "next") {
        pageUrl = pageUrl + this.pagination.next_page_url;
      }


      if (pg == null) {




        if (this.filterType == 'all') {
          this.filterValue = ''
          this.filterType = ''
        }


        if (this.filterValue != null && this.filterType != 'all') {
          if (this.filterType == 'title') {
            pageUrl += `staff/clientserviceupgrade?per_page=${this.per_page}&searchTerm=${this.filterValue}`;

          } else {
            pageUrl += `staff/clientserviceupgrade?per_page=${this.per_page}&${this.filterType}=${this.filterValue}`;

          }
        } else {
          pageUrl += `staff/clientserviceupgrade?per_page=${this.per_page}`;
        }





      } else {
        pageUrl += `&per_page=${this.pagination.per_page}`;
      }
      if (this.table_search) {
        pageUrl += `&searchTerm=${this.table_search}`;
      }
      if (page != null) {
        pageUrl += `&page=${page}`;
      }




      this.$axios
        .get(pageUrl, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res);
          this.clientServices = res.data.data.data;
          this.pagination = res.data.data;
          this.filterValue = ''
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          if( error.response.data.error == 'Sorry! You do not have permission to access.'){
            this.permissionCheck.viewAny = false
          }
            this.$swal.fire({
              position: "top-center",
              icon: "error",
              title: "<small>" + error.response.data.error + "</small>",
              showConfirmButton: true,
              confirmButtonColor: "#00364f",
            });
          });
    },
    stsChangeStafSave() {
      this.loading = true;
      var q = "";
      if (this.stsFromChange.status == "rejected") {
        q = "clientserviceprofessional/reject";
      } else {
        q = "completeclientservice";
      }

      this.$axios
        .post(`staff/${q}`, this.stsFromChange, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          res;
          // this.clientServices = res.data.data.data;
          toast.success("Status Changed Succesfully", {
            autoClose: 1000,
          });
          $("#projectcompleatedModel").modal("hide");
          this.loadClients();
        })
        .finally(() => (this.loading = false));
    },
    loadClientSericeProfesssionalSteps() {
      this.$axios
        .get(`staff/clientserviceprofessionalstep`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })

        .then((res) => {
          res;
          // console.log(res.data.data.data);
          var result = res.data.data.data;
          for (var i = 0; i < result.length; i++) {
            this.proSteps.push(result[i].step_id);
          }
        });
    },
    findRecordeByProjectId(e) {
      this.$axios
        .get(`staff/clientservice?clientServiceNo=${e.target.value}`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {

          this.filterValue = res.data.data.data[0].id
          this.loadClients()

        })
    },
    loadPageTitle() {
      document.title = this.$store.state.staffPageTitles.upgrade
    }
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.loadClients();
      this.permissionsCheker();
      this.loadPageTitle()
    }
  },
};
</script>
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
}

.table-scroll {
  overflow-y: auto;
}

.form-select {
  border: 1px solid #00364f;
}

.form-checkbox {
  width: 25px;
  margin-top: 5px;
}

nav ul {
  height: 300px;
  width: 100%;
}

nav ul {
  overflow: hidden;
  overflow-y: scroll;
}

div.scrollmenu {
  overflow: auto;
  white-space: nowrap;
}

div.scrollmenu a {
  display: inline-block;
  color: white;
  font-family: "Poppins", sans-serif;
  color: #00364f;
  text-decoration: none;
}

div.scrollmenu a .card {
  height: 50%;
  width: 40%;
}

.come-from-right .modal-dialog {
  position: fixed;
  top: 0px;
  right: 0px;
  margin: auto;
  width: 300px;
  height: 100%;
  transform: translate3d(0%, 0, 0);
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

.come-from-right .modal-content {
  height: 100%;
  overflow-y: auto;
  border-radius: 0px;
}

.come-from-right .modal-body {
  padding: 15px 15px 80px;
}

@media screen and (max-width: 767px) {
  .mt-top-numbers {
    margin-top: -26px;
  }
}

.h1-heading {
  color: red;
  font-size: 3vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.btns {
  display: inline-flex;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

.table-scroll {
  overflow-y: auto;
}

.page-item.active .page-link {
  z-index: 3;
  border-radius: 5rem;
  background-color: #00364f;
  color: #fff !important;
  font-weight: 600;
}

h4[data-v-69e5a8da] {
  color: #fff;
  font-family: poppins, Sans-serif;
  font-weight: 600;
  font-size: 25;
}

.form-check-input:checked {
  background-color: #ffffff;
  border-color: #f21000;
  border-width: 5px;
}

.form-check-input:not(:disabled):checked {
  box-shadow: 0 2px 4px 0 rgb(225 0 0 / 28%);
}






.cus-p-1 {
  padding: 5px 5px 5px 5px;
}

@media only screen and (min-width: 600px) {
  .justify-content-center {
    justify-content: end !important;
  }
}

.card-footer {
  padding: 0px 10px 0px 10px;
}
</style>
